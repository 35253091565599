<template>
  <div id="deliveries-view">
    <vs-popup classContent="popup-example" :title="current === 'deliverytimes' ? $t('deliveryTimeRanges') : $t('deliveryTypes')" :active.sync="showModal">
      <template v-if="current === 'deliverytimes'">
        <div class="grid grid-cols-2 gap-4">
          <flat-pickr v-validate="'required'" v-model="newDeliverytime.starts" :config="configdateTimePicker" class="w-full inputx" name="from" :placeholder="$t('discount.selectStartTime')" />
          <span class="text-danger text-sm" v-show="errors.has('from')">{{ errors.first('from') }}</span>
          <flat-pickr v-validate="'required'" v-model="newDeliverytime.ends" :config="configdateTimePicker" class="w-full inputx mt-3" name="to" :placeholder="$t('discount.selectEndTime')" />
          <span class="text-danger text-sm" v-show="errors.has('to')">{{ errors.first('to') }}</span>
        </div>
        <div class="flex mt-3">
          <vs-button @click="addDeliverytime()" color="primary" class="mt-5 ml-auto" type="filled">{{$t('submit')}}</vs-button>
        </div>
      </template>

      <template v-if="current === 'deliveries'">
        <div class="mb-2">
          <h5>{{ $t('fields.title') }}:</h5>
        </div>
        <vs-row>
          <div class="w-full">
            <vs-input v-validate="'required'" name="title_tm" class="w-full mb-1" :placeholder="$t('props.title_tm')" v-model="newDelivery.title_tm" />
            <span class="text-danger text-sm" v-show="errors.has('title_tm')">{{ errors.first('title_tm') }}</span>
          </div>
          <div class="w-full">
            <vs-input v-validate="'required'" name="title_ru" class="w-full mb-1" :placeholder="$t('props.title_ru')" v-model="newDelivery.title_ru" />
            <span class="text-danger text-sm" v-show="errors.has('title_ru')">{{ errors.first('title_ru') }}</span>
          </div>
          <div class="w-full">
            <vs-input v-validate="'required'" name="title_en" class="w-full mb-1" :placeholder="$t('props.title_en')" v-model="newDelivery.title_en" />
            <span class="text-danger text-sm" v-show="errors.has('title_en')">{{ errors.first('title_en') }}</span>
          </div>
        </vs-row>
        <div class="vx-row px-4 mt-4 mb-2">
          <h5>{{ $t('fields.type') }} {{$t('and')}} {{ $t('fields.cost') }}:</h5>
        </div>
        <div class="vx-row px-4">
          <vs-input v-validate="'required'" name="type" class="w-1/2 inputx pr-2 mb-1" :placeholder="$t('fields.type')" v-model="newDelivery.type" />
          <vs-input v-validate="'required'" name="price" class="w-1/2 inputx pl-2 mb-1" :placeholder="$t('fields.cost')" v-model="newDelivery.price" />
          <span class="text-danger text-sm d-block w-1/2" v-show="errors.has('type')">{{ errors.first('type') }}</span>
          <span class="text-danger text-sm d-block w-1/2 pl-2" v-show="errors.has('price')">{{ errors.first('price') }}</span>
        </div>
        <div class="flex mt-5">
          <vs-button @click="addDelivery()" color="primary" class=" ml-auto" type="filled">{{$t('submit')}}</vs-button>
        </div>
      </template>
      
    </vs-popup>
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <div v-show="!isLoading">
      <vx-card>
        <vs-table hoverFlat ref="table" :data="deliveries" stripe >
          <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between mb-3">
            <h3>{{ $t('deliveryTypes') }}:</h3>
            <div class="flex flex-no-wrap">
              <div class="flex flex-wrap-reverse items-center">
                <div v-if="$acl.check('admin')" class="btn-delete p-3 mb-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="addNew('deliveries')">
                  <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
                  <span class="ml-2 text-base">{{ $t('add_new') }}</span>
                </div>
              </div>
            </div>
          </div>
          <template slot="thead">
            <vs-th class="pr-0">{{ $t('fields.id') }}</vs-th>
            <vs-th class="pr-0">{{ $t('fields.type') }}</vs-th>
            <vs-th class="pr-0">{{ $t('fields.code') }}</vs-th>
            <vs-th class="">{{ $t('fields.cost') }}</vs-th>
            <vs-th class="">{{ $t('fields.name_tm') }}</vs-th>
            <vs-th class="hidden lg:table-cell">{{ $t('fields.name_ru') }}</vs-th>
            <vs-th class="hidden lg:table-cell">{{ $t('fields.name_en') }}</vs-th>
            <vs-th v-if="$acl.check('admin')">{{ $t('fields.actions') }}</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr v-for="(tr, indextr) in data" :key="indextr" class="pt-3">
              <vs-td :data="data[indextr].id" class=" hidden lg:table-cell">
                {{ data[indextr].id }}
              </vs-td>
              <vs-td class="pr-0">
                <feather-icon v-if="tr.type" icon="StarIcon" svgClasses="w-5 h-5 text-warning" />
                {{ data[indextr].type }}
              </vs-td>
              <vs-td :data="data[indextr].code" class=" hidden lg:table-cell">
                {{ data[indextr].code }}
              </vs-td>
              <vs-td class="" :data="data[indextr].price">
                {{ data[indextr].price }}
              </vs-td>
              <vs-td :data="data[indextr].title_tm" class=" hidden lg:table-cell">
                {{ data[indextr].title_tm }}
              </vs-td>
              <vs-td :data="data[indextr].title_ru" class="hidden lg:table-cell">
                {{ data[indextr].title_ru }}
              </vs-td>
              <vs-td :data="data[indextr].title_en" class="hidden lg:table-cell">
                {{ data[indextr].title_en }}
              </vs-td>
              <vs-td v-if="$acl.check('admin')" class="whitespace-no-wrap">
                <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" title="Edit banner details" @click.stop="editData('deliveries', tr)" />
                <feather-icon v-if="data[indextr].type !== 'express' && data[indextr].type !== 'market'" icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteData('deliveries', tr.uuid)" />
                <!-- <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteData('deliveries', tr.uuid)" /> -->
              </vs-td>
            </vs-tr>
          </template>

        </vs-table>
      </vx-card>

      <vx-card class="mt-5">
        <vs-table hoverFlat ref="table" :data="deliverytimes" stripe >
          <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
            <h3 class="mb-3">{{ $t('deliveryTimeRanges') }}:</h3>
            <div class="flex flex-no-wrap">
              <div class="flex flex-wrap-reverse items-center">
                <div v-if="$acl.check('admin')" class="btn-delete p-3 mb-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="addNew('deliverytimes')">
                  <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
                  <span class="ml-2 text-base">{{ $t('add_new') }}</span>
                </div>
              </div>
            </div>
          </div>
          <template slot="thead">
            <vs-th class="pr-0">ID</vs-th>
            <!-- <vs-th class="">{{ $t('fields.starts') }}</vs-th> -->
            <vs-th class="">{{ $t('fields.fromTo') }}</vs-th>
            <vs-th class="">{{ $t('fields.owner') }}</vs-th>
            <vs-th v-if="$acl.check('admin')">{{ $t('fields.actions') }}</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr v-for="(tr, indextr) in data" :key="indextr" class="pt-3">
              <vs-td class="pr-0">
                {{ data[indextr].id }}
              </vs-td>
              <vs-td :data="data[indextr].starts" class=" hidden lg:table-cell">
                {{ data[indextr].starts }}:00 - {{ data[indextr].ends }}:00
              </vs-td>
              <!-- <vs-td :data="data[indextr].ends" class="hidden lg:table-cell">
              </vs-td> -->
              <vs-td class="" :data="data[indextr].ownerId">
                {{ data[indextr].ownerId || '---' }}
              </vs-td>
              
              <vs-td v-if="$acl.check('admin')" class="whitespace-no-wrap">
                <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" title="Edit banner details" @click.stop="editData('deliverytimes', tr)" />
                <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteData('deliverytimes', tr.uuid)" />
                <!-- <feather-icon icon="RepeatIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" title="Change banner status" @click.stop="changeStatus(tr.uuid, tr.type, tr.isActive)" /> -->
              </vs-td>
            </vs-tr>
          </template>

        </vs-table>
      </vx-card>
    </div>
  </div>
</template>

<script>
import 'flatpickr/dist/flatpickr.css'
export default {
  components: {
    flatPickr: () => import('vue-flatpickr-component')
  },
  data () {
    return {
      deliveries: [],
      newDelivery: {},
      deliverytimes: [],
      newDeliverytime: {
        starts: '',
        ends: ''
      },
      isLoading: true,
      showModal: false,
      current: '',
      configdateTimePicker: {
        enableTime: true,
        noCalendar: true,
        time_24hr: true
      },
      isEditingDelivery: false,
      isEditingDeliverytime: false
    }
  },
  async created () {
    await this.fetchDelivery()
    await this.fetchDeliverytime()
    this.isLoading = false
  },
  methods: {
    async fetchDelivery () {
      await this.$http.get('/deliveries').then(response => {
        this.deliveries = response.data
      })
    },
    async fetchDeliverytime () {
      await this.$http.get('/deliverytimes').then(response => {
        this.deliverytimes = response.data.map(el => { return { ...el, type: 'deliverytimes' } })
      })
    },
    addNew (type) {
      this.current = type
      this.showModal = true
      this.newDelivery = {}
      this.newDeliverytime = {}
      this.isEditingDelivery = false
      this.isEditingDeliverytime = false
      this.$validator.pause()
      this.$nextTick(() => {
        this.$validator.errors.clear()
        this.$validator.fields.items.forEach(field => field.reset())
        this.$validator.fields.items.forEach(field => this.errors.remove(field))
        this.$validator.resume()
      })
    },
    addDelivery () {
      this.$validator.validateAll().then(result => {
        if (result) {
          if (!this.isEditingDelivery) {
            this.$http.post('/deliveries/add', {
              ...this.newDelivery
            }).then(() => {
              this.notify('success', 'Delivery successfully created!')
              this.fetchDelivery()
              this.showModal = false
            }).catch(err => {
              this.notify('error', err.message)
            })
          } else {
            this.$http.patch(`/deliveries/edit/${this.newDelivery.uuid}`, {
              ...this.newDelivery
            }).then(() => {
              this.notify('success', 'Delivery successfully updated!')
              this.fetchDelivery()
              this.showModal = false
            }).catch(err => {
              this.notify('error', err.message)
            })
          }
        } else {
          this.notify('error', 'Form is invalid. Check and try again')
        }
      })
    },
    addDeliverytime () {
      this.$validator.validateAll().then(result => {
        if (result) {
          if (!this.isEditingDeliverytime) {
            this.$http.post('/deliverytimes/add', {
              starts: Number(this.newDeliverytime.starts.slice(0, 2)),
              ends: Number(this.newDeliverytime.ends.slice(0, 2))
            }).then(() => {
              this.notify('success', 'Deliverytime successfully created!')
              this.fetchDeliverytime()
              this.showModal = false
            }).catch(err => {
              this.$vs.notify('error', err.message)
            })
          } else {
            this.$http.patch(`/deliverytimes/edit/${this.newDeliverytime.uuid}`, {
              starts: Number(this.newDeliverytime.starts.slice(0, 2)),
              ends: Number(this.newDeliverytime.ends.slice(0, 2))
            }).then(() => {
              this.notify('success', 'Deliverytime successfully updated!')
              this.fetchDeliverytime()
              this.showModal = false
            }).catch(err => {
              this.notify('error', err.message)
            })
          }
        } else {
          this.$vs.notify('Error', 'Form is invalid. Check and try again')
        }
      }).catch(() => { return null })
    },
    editData (type, item) {
      this.$validator.pause()
      this.$nextTick(() => {
        this.$validator.errors.clear()
        this.$validator.fields.items.forEach(field => field.reset())
        this.$validator.fields.items.forEach(field => this.errors.remove(field))
        this.$validator.resume()
      })
      this.current = type
      // this.isEditing = true
      this.showModal = true
      if (type === 'deliveries') {
        this.isEditingDelivery = true
        this.newDelivery = item
      } else {
        this.isEditingDeliverytime = true
        this.newDeliverytime = {
          ...item,
          starts: `${item.starts}:00`,
          ends: `${item.ends}:00`
        }
      }
    },
    deleteData (type, id) {
      this.$http.delete(`/${type}/delete/${id}`).then(response => {
        if (response.status === 200) {
          this.notify('success', 'Delivery successfully deleted!')
          if (type === 'deliveries') this.fetchDelivery()
          else this.fetchDeliverytime()
        }
      }).catch(err => {
        this.notify('error', err.message)
      })
    },
    notify (type, msg) {
      if (type === 'success') {
        this.$vs.notify({
          title: 'Success',
          text: msg,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'primary'
        })
      } else {
        this.$vs.notify({
          title: 'Error',
          text: msg,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
    }
  }
}
</script>

<style lang="scss">
#deliveries-view {
  .popup-example {
    padding: 30px;
  }
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }
    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      // margin-left: 1.5rem;
      // margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }
  }
}
</style>
